.img {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, .08), 0 0 6px rgba(0, 0, 0, .05);
  transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
  padding: 14px 80px 18px 36px;
  cursor: pointer;
  margin: 10px;
  border: 1px solid rgb(214, 214, 214);
}

.single_product {
  width: 14rem !important;
}

.zzz {
  min-height: 200px;
}

.img:hover {
  /* -webkit-filter: brightness(70%); */
  /* filter: contrast(200%); */
  /* -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease; */

  transform: scale(1.05);
  /* box-shadow: 5px 5px 20px #9ad0ff; */
  box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
}


.contact-info {
  font-size: 20px;
}

@media screen and (max-width:500px) {
  .contact-info {
    font-size: calc(.5rem + .5rem);
  }

  .google-map-col {
    padding-top: 5%;
    padding-bottom: 5%;
  }
}

.contact-padding-top {
  padding-top: 3%;
}

.contact-address-align {
  text-align: center;
}


.contact-align {
  text-align: center;
  padding-top: 6%;
}

a.subnav_link:link {
  color: #0275d8;
  text-decoration: none;
}

a.subnav_link:visited {
  color: black;
}

a.subnav_link:hover {
  /* MUST OCCUR AFTER VISITED */
  cursor: pointer;
  color: #0275d8;
}

a.subnav_link:active {
  color: #0275d8;
}

.my-class {
  border-radius: 15%;
  transition: 400ms;
}

.my-class:hover {
  border-radius: 0%;
  transition: 400ms;
}

.xxx {
  height: 70px;
  font-size: 30px;
  max-width: 89% !important;
  font-size: 20px !important;
}

.search-bar {
  width: 80%;
  height: 7%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

.search-bar input {
  flex: 1;
  padding: 15px 15px;
  font-size: 15px;
  border-radius: 15px;
}

.search-button {
  margin-right: 10%;
}

.product {
  display: flex;
  align-items: center;
  margin-right: 8%;
  margin-left: 8%;
  margin-bottom: -5%;
}

.product-image {
  width: 400px;
  height: auto;
}

.product-description h3 {
  font-size: 25px;
  margin-bottom: 10px;
}

.product-description p {
  font-size: 18px;
  color: #000000;
}

@media screen and (max-width:500px) {
  .product-image {
    width: 200px;
    height: auto;
  }

  .product-description p {
    font-size: 13px;
    color: #000000;
  }
}