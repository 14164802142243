/* .my_slides{
    max-width: 1000px;
    display: block;
}

.my-slide-photo{
    height: 600px;
} */

.carousel-caption {
    left: 0 !important;
    background-color: rgba(0, 0, 0, .5);
    width: 100%;
    max-height: 50%;
    color: white;
    text-shadow: 2px 2px 4px #000000;
    font-size: 40px;
    /* text-align: center; */
    align-items: center;

}

@media screen and (max-width:500px) {
    .carousel-caption {
        font-size: calc(.6rem + .6rem);
    }
}

.carousel-container {
    position: relative;
    max-width: 432px;
    max-height: 600px;


}

.carousel.slide.img {
    height: 100%;
    width: auto;
}