.container_column {
    padding-top: 1%;
    margin-left: 3%;
    margin-right: 3%;
}

.c_image {
    height: 200px;
    max-width: 100%;
}

.dropdown-menu {
    max-height: 450px;
    overflow-y: scroll;
}

.product-header-col {
    padding-left: 5%;
    padding-right: 5%;
}

.my-nav-container {
    padding-left: 8.5% !important
}

.product-header-font {
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 4%;
}

.square-row {
    padding-top: 3%;
    margin-right: 8% !important;
    margin-left: 8% !important;
    align-content: center;
}

.about-us-row {
    padding-top: 2%;
    margin-left: 8% !important;
    margin-right: 8% !important;
}

.my-array-col {
    padding-top: 0.7%;
    margin-bottom: 4%;
}

.my-accodrion-row {
    padding-left: 5%;
    padding-right: 5%;
}


@media screen and (max-width:500px) {
    .product-header-font {
        font-size: calc(.6rem + .6rem);
    }

    .start-square {
        margin-top: 4%;
        margin-bottom: 4%;
    }

    .about-us-row {
        padding-top: 0%;
    }

    .square-row {
        padding-top: 5%;

    }
}